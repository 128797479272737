import i18n from "@/Language/i18n";
import { ifRouterRedirect, lazyWithErrorHandle } from "@/utils/utils";

export default {
  path: "/overseasShellSpeedShop",
  meta: {
    title: i18n.t("Overseas_sales_speed_Shop"),
    roles: ["overseasShellSpeedShop"],
  },
  redirect: ifRouterRedirect("overseas"),
  children: [
    {
      path: "overseasShellSpeedShop-overseasShopNumber",
      meta: {
        title: i18n.t("StoreAmmeter"),
        roles: ["overseasShellSpeedShop-overseasShopNumber"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/OverseasShopNumber")),
    },
    // 店铺一览 直营
    {
      path: "overseasShellSpeedShop-OverseasDirectStore",
      meta: {
        title:
          JSON.parse(sessionStorage.getItem("userInfo")!)?.countryCD !== "00"
            ? i18n.t("OverseasDirectStorePage_title_store_list")
            : i18n.t("OverseasDirectStorePage_title_direct_sales"),
        roles: ["overseasShellSpeedShop-OverseasDirectStore"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/OverseasDirectStore")),
    },
    // 店铺一览 特许经营
    {
      path: "overseasShellSpeedShop-OverseasDirectStoreFranchise",
      meta: {
        title: i18n.t("OverseasDirectStorePage_title_franchise"),
        roles: ["overseasShellSpeedShop-OverseasDirectStoreFranchise"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/OverseasDirectStoreFranchise")),
    },
  ],
};
