import { Result, Spin } from "antd";
import { memo } from "react";

export default memo(() => {
  return (
    <div
      style={{
        width: "100vm",
        height: "calc( 100vh - 130px )",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Result icon={<Spin size="large" />} />
    </div>
  );
});
