import PageLoading from "@/components/PageLoading";
import { currentUserPermission } from "@/store/currentUserPermission";
import { routerRoles } from "@/store/routerRoles";
import { userInfo } from "@/store/userInfo";
import {
  flatMapCircuitToGetAField,
  getToken,
  isInterfaceSuccess,
} from "@/utils/utils";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getAllMenuInfo, getCurrentUserInfo } from "./service";
import { AuthorityPage, Page } from "./type";

export default memo(({ children }: { children: React.ReactNode }) => {
  const pushRouter = useNavigate();

  const [pageLoading, setPageLoading] = useState<Boolean>(true);

  // 用户对应的路由权限数组
  const setRouterRoles = useSetRecoilState(routerRoles);

  // 当前用户的权限
  const setCurrentUserPermissions = useSetRecoilState(currentUserPermission);

  // 用户信息
  const setUserInfo = useSetRecoilState(userInfo);

  /**
   * 获取用户信息
   * @return { void }
   */
  const Interface_getUserInfo = async (): Promise<void> => {
    try {
      let response = await getCurrentUserInfo();
      // console.log("当前用户信息", response.userInfo);
      isInterfaceSuccess(response.code) && setUserInfo(response.userInfo);
    } catch (error) {
    } finally {
    }
  };

  /**
   * 过滤出按钮权限字符
   * @param  { AuthorityPage }  authorityPage  用户菜单所有信息
   * @return { string[] }
   */
  function filterAuthority(authorityPage: AuthorityPage): string[] {
    let result: string[] = [];

    function processPage(page: Page, parentCode = "") {
      const fullPageCode = parentCode
        ? `${parentCode}__${page.pageCode}`
        : page.pageCode;

      if (page.authorities) {
        page.authorities.forEach((auth) => {
          const combination = `${fullPageCode}__${auth.code}`;
          result.push(combination);
        });
      }

      if (page.children) {
        page.children.forEach((child) => {
          processPage(child, fullPageCode);
        });
      }
    }

    authorityPage.forEach((rootPage) => {
      processPage(rootPage);
    });

    return result.map((item) => item.toUpperCase());
  }

  /**
   * 获取用户菜单信息
   * @return { void }
   */
  const Interface_getUserMenu = async (): Promise<void> => {
    try {
      let response = await getAllMenuInfo();
      console.log(response.hierarchies);
      if (isInterfaceSuccess(response.code)) {
        setRouterRoles([
          ...flatMapCircuitToGetAField(response.hierarchies, "url", "children"),
        ]);

        // console.log(
        //   "当前用户的权限 List",
        //   filterAuthority(response.hierarchies)
        // );
        setCurrentUserPermissions(filterAuthority(response.hierarchies));
      }
    } catch (error) {
    } finally {
    }
  };

  const init = async (): Promise<void> => {
    setPageLoading(true);

    if (!getToken()) {
      pushRouter("/user/login");
      return;
    }

    await Interface_getUserInfo();
    await Interface_getUserMenu();
    setPageLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return <>{pageLoading ? <PageLoading /> : children}</>;
});
