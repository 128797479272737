import { PASSWORD_MAXLEN, STAFFCD_MAXLEN } from "@/utils/config.ts";
import { ifUserGoRouter, setToken, setUserInfo } from "@/utils/utils";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Spin, message } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { loginEdit } from "../service";
import styles from "./index.module.less";
import ForgetModal from "../ForgetModal";

type LogimModalProps = {
  handleClose: (user?: API.UserInfo) => void;
  openEdtPwModal: () => void;
};

const LogimModal: FC<LogimModalProps> = ({ handleClose, openEdtPwModal }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loginForm] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);

  const controlModalVisible = (flag: boolean) => {
    setVisible(flag);
  };
  const hanleLogin = async () => {
    try {
      setLoading(true);
      const validateRes = await loginForm.validateFields();
      if (validateRes) {
        const values = loginForm.getFieldsValue();
        const data = {
          loginType: "native",
          staffCD: values.staffCD?.trim(),
          password: values.password?.trim(),
        };
        const res = await loginEdit(data as API.LoginReq);
        if (Number(res?.code) > 0) {
          if (res.user.passFlag) {
            handleClose(res.user);
            openEdtPwModal();
          } else {
            message.success(t("Common_message_loginSuccess"));
            setToken(res.token);
            setUserInfo(res.user);
            setBtnDisabled(true);
            setTimeout(() => {
              ifUserGoRouter(res.user.userRoleCd);
            }, 1000);
          }
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t("LoginPage_loginModal_title")}
      open={true}
      mask
      maskClosable={false}
      keyboard={false}
      destroyOnClose
      okText={t("Common_submitlBtn_text")}
      cancelText={t("Common_cancelBtn_text")}
      closable={false}
      onOk={hanleLogin}
      width={600}
      okButtonProps={{
        className: "HJX_Button",
        loading,
        disabled: btnDisabled,
      }}
      onCancel={() => handleClose()}
      cancelButtonProps={{
        className: "HJX_Button",
        loading,
        disabled: btnDisabled,
        style: { backgroundColor: "white", color: "black" },
      }}
    >
      <div className={styles.LoginModal}>
        <Spin spinning={loading}>
          <Form
            className={styles.form}
            form={loginForm}
            layout="horizontal"
            colon={false}
            labelAlign="right"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Form.Item
              name="staffCD"
              label={t("LoginPage_staffCD_text")}
              rules={[
                {
                  required: true,
                  message: t("LoginPage_staffCD_error_empty"),
                },
                {
                  whitespace: true,
                  message: t("LoginPage_staffCD_error_emptyString"),
                },
              ]}
            >
              <Input
                className="form__input"
                prefix={<UserOutlined style={{ marginRight: 10 }} />}
                maxLength={STAFFCD_MAXLEN}
                placeholder={t("LoginPage_staffCD_placeholder")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("LoginPage_password_text")}
              rules={[
                {
                  required: true,
                  message: t("LoginPage_password_error_empty"),
                },
                {
                  whitespace: true,
                  message: t("LoginPage_password_error_emptyString"),
                },
              ]}
            >
              <Input.Password
                className="form__input"
                prefix={<LockOutlined style={{ marginRight: 10 }} />}
                maxLength={PASSWORD_MAXLEN}
                placeholder={t("LoginPage_password_placeholder")}
                visibilityToggle
              />
            </Form.Item>
            <a className="form__link" onClick={() => controlModalVisible(true)}>
              {t("LoginPage_forgetPassword_title")}
            </a>
          </Form>
        </Spin>
      </div>
      {visible ? <ForgetModal handleClose={controlModalVisible} /> : null}
    </Modal>
  );
};
export default LogimModal;
