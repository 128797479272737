import { atom } from "recoil";

/**
 * 当前用户可查看的路由权限数组
 * @return { string[] }
 * @see  {@link https://www.recoiljs.cn/docs/basic-tutorial/atoms }
 */
export const routerRoles = atom<string[]>({
  key: "routerRoles",
  default: [],
});
