import i18n from "@/Language/i18n";
import { ifRouterRedirect, lazyWithErrorHandle } from "@/utils/utils";

export default {
  path: "/jurisdiction",
  meta: {
    title: i18n.t("Admin_menu"),
    roles: ["jurisdiction"],
  },
  redirect: ifRouterRedirect("jurisdiction"),
  children: [
    {
      path: "jurisdiction-userViewScreen",
      meta: {
        title: i18n.t("User_Overview_Screen"), // 用户一栏画面
        roles: ["userView-Screen"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/UserViewScreen")),
    },
    {
      path: "jurisdiction-roleViewScreen", // 角色一览画面
      meta: {
        title: i18n.t("Character_view_screen"),
        roles: ["jurisdiction-roleViewScreen"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/RoleViewScreen")),
    },
    {
      path: "jurisdiction-cadreAllTogether", // コードリスト
      meta: {
        title: i18n.t("Code_List"),
        roles: ["jurisdiction-cadreAllTogether"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/CadreAllTogether")),
    },
    {
      path: "jurisdiction-firmAdmin", // 事業・業態リスト
      meta: {
        title: i18n.t("FirmAdmin_router_name"),
        roles: ["jurisdiction-firmAdmin"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/FirmAdmin")),
    },
    {
      path: "jurisdiction-developMaster", // ディベロッパーマスタ
      meta: {
        title:
          JSON.parse(sessionStorage.getItem("userInfo")!)?.countryCD === "00"
            ? i18n.t("DevelopMasterPage_title")
            : i18n.t("DevelopMasterPage_title_ntJP"),
        roles: ["jurisdiction-developMaster"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/DevelopMaster")),
    },
    {
      path: "jurisdiction-regionalManagement", // 地区管理
      meta: {
        title:
          JSON.parse(sessionStorage.getItem("userInfo")!)?.countryCD === "00" ? i18n.t("regional_management") : i18n.t("regional_management_ntJP"),
        roles: ["jurisdiction-RegionalManagement"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/AreaAdmin")),
    },
    {
      path: "jurisdiction-YenExchangeRateSetting", // 円換算レート設定
      meta: {
        title: i18n.t("YenExchangeRateSetting"),
        roles: ["rateSetting"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/YenExchangeRateSetting")),
    },
    {
      path: "jurisdiction-publicDateSettings", // 周知日设定
      meta: {
        title: i18n.t("Public_date_Settings"),
        roles: ["jurisdiction-publicDateSettings"],
      },
      component: lazyWithErrorHandle(() => import("@/pages/PublicDateSettings")),
    },
  ],
};
