import "@/Language/i18n";
import Page from "@/router/index";
import { DEFAULT_LANGUAGE } from "@/utils/config";
import { getLanguageTypeInfo } from "@/utils/utils";
import { ConfigProvider, message, notification } from "antd";
import { Locale } from "antd/es/locale";
import enUS from "antd/es/locale/en_US"; // English
import jaJP from "antd/es/locale/ja_JP"; // 日本語
import thTH from "antd/es/locale/th_TH"; // ภาษาไทย
import zhCN from "antd/es/locale/zh_CN"; // 中国
import dayjs from "dayjs";
import "dayjs/locale/en"; // English
import "dayjs/locale/ja"; // 日本語
import "dayjs/locale/th"; // ภาษาไทย
import "dayjs/locale/zh-cn"; // 中国
import arraySupport from "dayjs/plugin/arraySupport";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from "dayjs/plugin/timezone";
import toArray from "dayjs/plugin/toArray";
import utc from "dayjs/plugin/utc";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./App";
import "./index.less";

// // Mock
// import "../Mock/DictViewScreen";
// import "../Mock/OverseasDirectStore";
// import "../Mock/overseasShopNumber";
// import "../Mock/roleViewScreen";
// import "../Mock/userViewScreen";

const languageInfo = getLanguageTypeInfo() ?? DEFAULT_LANGUAGE;

const dateNationList: Record<string, string> = {
  ja_JP: "Asia/Tokyo",
  en_US: "America/New_York",
  th_TH: "Asia/Bangkok",
  zh_CN: "Asia/Shanghai",
};

const antdLanguageList: Record<string, Locale> = {
  ja_JP: jaJP,
  en_US: enUS,
  th_TH: thTH,
  zh_CN: zhCN,
};

dayjs.extend(utc);
dayjs.extend(toArray);
dayjs.extend(timezone);
dayjs.extend(arraySupport);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.tz.setDefault(dateNationList[languageInfo]);

message.config({ maxCount: 2 });
notification.config({ maxCount: 3 });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <RecoilRoot>
      <ConfigProvider
        componentSize="small"
        locale={antdLanguageList[languageInfo]}
      >
        <Page />
      </ConfigProvider>
    </RecoilRoot>
  </BrowserRouter>
);
