// import { flexCrosswiseLayout,
// flexAroundLayout,
// flexVerticalLayout,
// ellipsisStyle
// } from "@/utils/flexLayout";

export const flexCrosswiseLayout = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const flexAroundLayout = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

export const flexVerticalLayout = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
};

export const ellipsisStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
