import { atom } from "recoil";

type menuLayoutType = "horizontal" | "inline";

/**
 * 路由菜单布局
 * @see  {@link https://www.recoiljs.cn/docs/basic-tutorial/atoms }
 */
export const menuLayout = atom<menuLayoutType>({
  key: "menuLayout",
  default: "horizontal",
});
