import { ResponseInterfaceInfo } from "@/@types/publicType";
import api from "@/api/api";
import request from "@/utils/request";
import { CountryAreaType, UserInfoType, UserMenuInfoType } from "./type";

export const getAllMenuInfo = (): Promise<UserMenuInfoType> => {
  return request.get(api.system_currentMenu);
};

export const getCurrentUserInfo = (): Promise<UserInfoType> => {
  return request.get(api.system_currentUser);
};

export const logout = ({}): Promise<ResponseInterfaceInfo> => {
  return request.post(api.system_logout, {});
};

export const getCountryAreaSelectInfo =
  (data: {}): Promise<CountryAreaType> => {
    return request.post(api.user_fetchCountry, data);
  };
