// import api from "@/api/api";
// import { logout } from "@/api/common";
import { DEFAULT_LANGUAGE } from "@/utils/config";
import { getLanguageTypeInfo, setLanguageTypeInfo } from "@/utils/utils";

/* window.addEventListener("unload", () => {
  const token = getToken();
  if (token) {
    fetch("/api" + api.system_logout, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
      keepalive: true,
    })
      .then(() => {})
      .catch(() => {});
  }
}); */
if (!getLanguageTypeInfo()) {
  setLanguageTypeInfo(DEFAULT_LANGUAGE);
}

document.title = import.meta.env.VITE_SYSTEM_NAME;
