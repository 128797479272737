import i18n from "@/Language/i18n";
import UserLoginLaterLayout from "@/Layout/UniversalLayoutRoutes/router/index";
import itemLogo from "@/assets/itemLogo.png";
import SelectLang from "@/components/SelectLang";
import EditPasswordModal from "@/pages/Login/EditPasswordModal";
import LoginModal from "@/pages/Login/LoginModal";
import { routerRoles } from "@/store/routerRoles";
import { userInfo } from "@/store/userInfo";
import { LOGINOUT_TIME, OVERSEAS_SHOP_NUMBER } from "@/utils/config";
import { flexCrosswiseLayout } from "@/utils/flexLayout";
import { clearAuthInfo, detectDevice, getLanguageTypeInfo, getUserInfo, isInterfaceSuccess } from "@/utils/utils";
import { HomeOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Layout, Menu, MenuProps, message } from "antd";
import Sider from "antd/es/layout/Sider";
import { Header } from "antd/es/layout/layout";
import { memo, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { logout } from "../service";
import "./index.less";
import styles from "./index.module.less";
import { CoverMenuType, MenuListType, RouterProps, UniversalLayoutProps } from "./interface/type";

export default memo(({ children, menuLocation }: UniversalLayoutProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const routerRolesList = useRecoilValue(routerRoles);
  const [fakeUser, setFakeUser] = useState<API.UserInfo | undefined>(undefined);

  let timeOut: any = useRef<any>(null);
  useEffect(() => {
    document.addEventListener("mousemove", isLogout);
    document.addEventListener("click", isLogout);
    document.addEventListener("keydown", isLogout);
    isLogout();
  }, []);

  // 当前用户信息
  const currentUserInfo = useRecoilValue(userInfo);

  // 权限数组
  let roleList = routerRolesList;

  // 控制左侧菜单折叠状态
  const [collapsed, setCollapsed] = useState<boolean>(true);

  // 当前路由路径
  let currentRouterPathName = useLocation().pathname;

  const pushRouter = useNavigate();

  const items: MenuProps["items"] = [
    {
      label: i18n.t("Common_editPasswordBtn_text"),
      key: "changePassword",
    },
    {
      label: i18n.t("Common_logoutBtn_text"),
      key: "loginExit",
    },
  ];

  /**
   * 权限过滤当前用户可查看路由
   * @param  { RouterProps[] }  formerRouter 原生路由树结构
   * @param  { string[] }       rolesList    当前用户路由权限数组
   * @return { RouterProps[] }
   */
  const generateRoleRouter = (formerRouter: RouterProps[], rolesList: string[]): RouterProps[] => {
    return formerRouter
      .map((item) => {
        if (!!item.children) {
          const filteredChildren = generateRoleRouter(item.children, rolesList);

          const childrenWithPermission = filteredChildren.filter((child) => rolesList.some((role) => child.meta?.roles.includes(role)));

          if (childrenWithPermission.length > 0) {
            return { ...item, children: childrenWithPermission };
          } else {
            return null;
          }
        }

        return rolesList.some((role) => item.meta?.roles.includes(role)) ? item : null;
      })
      .filter((item): item is RouterProps => item !== null) as RouterProps[];
  };

  /**
   * 转换成组件库可以识别的 menu 结构
   * @param  { RouterProps[] }   router 根据权限过滤之后的原生路由树结构
   * @return { CoverMenuType[] }  antd design Menu组件可识别的结构
   */
  const generateMenuFromRouter = (router: RouterProps[]): CoverMenuType[] => {
    return router.map((item) => {
      const { path, meta, children } = item;
      // 处理子路由
      let updatedChildren: CoverMenuType[] | any = children;
      if (children && children.length) {
        updatedChildren = children.map((child) => {
          return {
            key: `${path.split("-")[0]}/${item.path}/${child.path}`,
            label: child.meta?.title || "",
          };
        });
      }
      // 创建新的菜单项对象
      const menuRouter: CoverMenuType = {
        key: `${path.split("-")[0]}/${path}`,
        label: meta?.title || "",
        children: updatedChildren,
      };

      return menuRouter;
    });
  };

  /**
   * menu 组件所需数据
   * @return { MenuListType[] }
   */
  let menuList = generateRoleRouter(UserLoginLaterLayout, roleList)
    .map((item) => {
      let { path, children } = item;
      let { title, icon } = item.meta!;
      // if (roles[0] !== "overseasShellSpeedShop-overseasShopNumber") {
      return {
        key: path,
        label: title || "",
        icon: icon,
        children: children ? generateMenuFromRouter(children) : void 0,
      };
      // }
    })
    .concat([
      {
        label: i18n.t("OperationManual_pageTitle"),
        key: "/operationManual",
        icon: null,
        children: undefined,
      },
    ])
    .filter(Boolean);

  /**
   * 过滤掉不需要显示但是可以进入的路由
   * @return { MenuListType[] }
   */
  const filterMenuList = (menuList: any): MenuListType[] => {
    return menuList.filter((item: any) => {
      if (item.children) {
        item.children = filterMenuList(item.children);
      }
      return true;
    });
  };

  /**
   * 过滤出当前用户可查看路由的 pathName
   * @param  { MenuListType[] }  menuInfo antd menu 的数据信息
   * @return { string[] }        当前用户的所有路由页面  取 key 字段
   * @description                用于跟当前页面 pathName 做判断拦截
   */
  const userRouterKeys = (menuInfo: MenuListType[]): string[] => {
    return menuInfo.flatMap((item) => {
      let routerKeys = [item.key];
      if (item.children && item.children.length > 0) {
        routerKeys = [...routerKeys, ...userRouterKeys(item.children)];
      }
      return routerKeys.map((key) => (key.charAt(0) === "/" ? key.substring(1) : key));
    });
  };

  /**
   * Menu Change Router
   * @return { void }
   */
  const menuClick: MenuProps["onClick"] = (e): void => pushRouter(e.key);

  const isLogout = async () => {
    try {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
        timeOut.current = null;
      }
      timeOut.current = setTimeout(async () => {
        // 提示信息
        message.error(i18n.t("Common_autoLogout_tip"));
        const t = setTimeout(() => {
          clearTimeout(t);
          handleLogout();
        }, 3000);
      }, LOGINOUT_TIME);
    } catch (error) {}
  };

  const handleLogout = async () => {
    try {
      let response = await logout({});
      if (isInterfaceSuccess(response.code)) {
        pushRouter(getUserInfo()?.countryCD === "00" ? "/user/googleLogin" : "/user/login");
        clearAuthInfo();
        location.reload();
      }
    } catch (error) {}
  };

  /**
   * 退出当前账号
   * @return { Promise<void> }
   */
  const onClick: MenuProps["onClick"] = async ({ key }): Promise<void> => {
    if (key === "changePassword") {
      // pushRouter("/user/changePassword");
      setModalVisible(true);
    }

    if (key === "loginExit") {
      handleLogout();
    }

    if (key === "login") {
      setLoginVisible(true);
    }
  };
  // console.log(
  //   "userRouterKeys(menuList as any)",
  //   userRouterKeys(menuList as any),
  //   currentRouterPathName.substring(1)
  // );
  /**
   * 跳往主界面
   * @return { void }
   */
  const goHome = (): void => {
    // 日本用户跳往 店铺数页面
    if (detectDevice() === "desktop") {
      if (currentUserInfo && currentUserInfo.countryCD === "00") {
        pushRouter(OVERSEAS_SHOP_NUMBER);
      } else {
        // 东盟其它国家 跳往店铺一览页面
        pushRouter(OVERSEAS_SHOP_NUMBER);
        // pushRouter(
        //   "/overseasShellSpeedShop/overseasShellSpeedShop-OverseasDirectStore"
        // );
      }
    }
  };

  // 避免用户主动输入路径进入自身权限外的路由
  useEffect(() => {
    if (currentRouterPathName === "/") {
      currentRouterPathName = "/overseasShellSpeedShop";
    }
    // if (currentRouterPathName.substring(1) === "") {
    //   pushRouter(
    //     "/overseasShellSpeedShop/overseasShellSpeedShop-overseasShopNumber"
    //   );
    //   return;
    // }
    // if (
    //   currentRouterPathName ===
    //   "/overseasShellSpeedShop/overseasShellSpeedShop-overseasShopNumber"
    // ) {
    //   pushRouter(
    //     "/overseasShellSpeedShop/overseasShellSpeedShop-overseasShopNumber"
    //   );
    //   return;
    // }
    if (!userRouterKeys(menuList as any).includes(currentRouterPathName.substring(1))) {
      pushRouter(-1);
    }
  }, [currentRouterPathName]);

  const menuInfo = (
    <Menu
      items={filterMenuList(menuList)}
      mode={menuLocation}
      onClick={menuClick}
      className={styles.antdMenu}
      // defaultSelectedKeys={[
      //   "/overseasShellSpeedShop/overseasShellSpeedShop-overseasShopNumber",
      // ]}
      selectedKeys={[currentRouterPathName.split("/").length > 2 ? currentRouterPathName.substring(1) : currentRouterPathName]}
    />
  );

  // 用户名称
  const userNameDom = (
    <div className={styles.name_dropdown} onClick={(e: any) => e.preventDefault()}>
      <>
        <div className={`${styles.username} ellipsis`}>{currentUserInfo?.username ?? ""}</div>
        <div className={`${styles.userRoleName} ellipsis`}>
          {(getLanguageTypeInfo() === "ja_JP" ? currentUserInfo?.userRoleName : currentUserInfo?.userRoleNameEng) ?? ""}
        </div>
      </>
    </div>
  );

  // 当前用户下拉退出
  const dropdown = () => {
    return ["normal", "native"].includes(getUserInfo()?.loginType as string) ? (
      <Dropdown menu={{ items, onClick }} placement="bottom">
        {userNameDom}
      </Dropdown>
    ) : (
      <Dropdown
        menu={{
          items: [
            {
              label: i18n.t("LoginPage_loginModal_btn"),
              key: "login",
            },
          ],
          onClick,
        }}
        placement="bottom"
      >
        {userNameDom}
      </Dropdown>
    );
  };

  const topMenuLayout = (
    <Layout className={styles.global}>
      <div className={styles.top_header}>
        <div className={styles.header_left_box}>
          <div className="img_box">
            <img onClick={goHome} style={{ width: 70, height: 40 }} src={itemLogo} />
          </div>
          <div className={styles.item_name} style={{ color: import.meta.env.VITE_SYSTEM_NAME === "テストｍａｍｅｍｕｇｉ" ? "red" : "black" }}>
            {import.meta.env.VITE_SYSTEM_NAME}
          </div>
          <div className={detectDevice() === "desktop" ? styles.item_home_icon : styles.mobile_header}>
            <HomeOutlined onClick={goHome} />
          </div>
        </div>
        <div className={detectDevice() === "desktop" ? styles.header_right_box : styles.mobile_header}>
          <div className={styles.item_menu}>{menuInfo}</div>
          <div className={styles.devider} />
          <div className={styles.langeage_box}>
            {getUserInfo()?.countryCD === "00"
              ? null
              : [<SelectLang className={styles.universallayout_top_selectlang} />, <Divider className={styles.divider} type="vertical" />]}
            {dropdown()}
          </div>
        </div>
      </div>
      <div className={styles.center_content}>{children}</div>
      {modalVisible && (
        <EditPasswordModal
          userInfo={fakeUser || getUserInfo() || undefined}
          handleClose={() => {
            setFakeUser(undefined);
            setModalVisible(false);
          }}
          handleClose2={
            fakeUser &&
            (() => {
              setFakeUser(undefined);
              setModalVisible(false);
              setLoginVisible(true);
            })
          }
          passFlag={fakeUser ? true : false}
        />
      )}
      {loginVisible && (
        <LoginModal
          handleClose={(user) => {
            setFakeUser(user);
            setLoginVisible(false);
          }}
          openEdtPwModal={() => {
            setModalVisible(true);
          }}
        />
      )}
    </Layout>
  );

  // todo 左侧菜单暂时弃用
  const leftMenuLayout = (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        {menuInfo}
      </Sider>
      {/* // className="demo-logo" */}
      <Layout>
        <Header
          style={{
            padding: 0,
            backgroundColor: "#414141",
            height: "65px",
          }}
        >
          <div style={{ ...flexCrosswiseLayout, padding: "0 25px" }}>
            <div>......</div>

            <div>{dropdown()}</div>
          </div>
        </Header>
        {children}
      </Layout>
    </Layout>
  );

  return menuLocation === "horizontal" ? topMenuLayout : leftMenuLayout;
});
