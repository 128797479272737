import type { RouterProps } from "../../../router/index";
import OverseasSalesSpeed from "../routerInfo/OverseasSalesSpeed";
import Jurisdiction from "../routerInfo/jurisdiction";
import Overseas from "../routerInfo/overseas";
import { lazyWithErrorHandle } from "@/utils/utils";

const UserLoginLaterLayout: RouterProps[] = [
  // StoreAmmeter,
  Overseas,
  OverseasSalesSpeed,
  Jurisdiction,
  {
    path: "/operationManual",
    component: lazyWithErrorHandle(() => import("@/pages/OperationManual")),
  },
];

export default UserLoginLaterLayout;
