import { PASSWORD_MAXLEN, PASSWORD_MINLEN } from "@/utils/config";
import { Form, Input, Modal, Spin, message } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { changePassword } from "./service";
import { clearAuthInfo } from "@/utils/utils";
import styles from "./index.module.less";

type EditPasswordModalProps = {
  passFlag?: boolean;
  closable?: boolean;
  handleClose: (authClear?: boolean) => void;
  handleClose2?: () => void;
  okCallBack?: () => void;
  userInfo?: API.UserInfo;
};

const EditPasswordModal: FC<EditPasswordModalProps> = ({
  passFlag = false,
  closable = true,
  userInfo,
  handleClose,
  handleClose2,
  /* okCallBack, */
}) => {
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  /**
   * New_password校验 新旧密码不同
   * @param _
   * @param password New_password
   * @param callback
   */
  const checkPassword = (_: any, password: string, callback: Function) => {
    try {
      const oldPassword = form.getFieldValue("oldPassword");
      if (oldPassword && password === oldPassword) {
        callback(t("EditPassordPage_password_error_sameOld"));
      }
      callback();
    } catch (error) {}
  };

  //
  const checkPasswordSameStaffCD = (_: any, password: string, callback: Function) => {
    try {
      if (userInfo) {
        const { staffCD } = userInfo;
        if (staffCD && password === staffCD) {
          callback(t("EditPassordPage_password_error_sameCD"));
        }
      }
      callback();
    } catch (error) {}
  };

  /**
   * New_password强度校验
   * @param _
   * @param password
   * @param callback
   */
  const checkPasswordStrength = (_: any, password: string, callback: Function) => {
    try {
      const format = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      if (format.test(password)) {
        callback();
      }
      callback(t("EditPassordPage_password_error_strength"));
    } catch (error) {}
  };

  /**
   * 确认密码校验 确认New_password和New_password一致
   * @param _
   * @param confirmPassword 确认New_password
   * @param callback
   */
  const checkConfirmPassword = (_: any, confirmPassword: string, callback: Function) => {
    try {
      const password = form.getFieldValue("password");
      if (password) {
        if (confirmPassword !== password) {
          callback(t("EditPassordPage_confirmPassword_error_inconsistent"));
        }
      }
      callback();
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      if (!userInfo) return;
      setLoading(true);
      const validateRes = await form.validateFields();
      if (validateRes) {
        const values = form.getFieldsValue();
        const data = {
          id: userInfo.id,
          staffCD: userInfo.staffCD,
          oldPassword: values.oldPassword,
          password: values.password,
        };
        const res = await changePassword(data);
        if (Number(res?.code) > 0) {
          message.success(t("Common_message_editPasswordSuccess"));
          if (handleClose2 && typeof handleClose2 === "function") {
            handleClose2();
          } else {
            clearAuthInfo();
            setBtnDisabled(true);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }

          /* // 若是重置密码则跳转系统内页面 否则跳转
          if (passFlag && okCallBack && typeof okCallBack === "function") {
            okCallBack();
          } else {
            pushRouter("/user/login");
          } */
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={passFlag ? t("EditPassordPage_title_initial") : t("EditPassordPage_title")}
      open={true}
      mask
      maskClosable={false}
      keyboard={false}
      destroyOnClose
      okText={t("Common_submitlBtn_text")}
      cancelText={t("Common_cancelBtn_text")}
      closable={closable}
      onOk={handleSubmit}
      width={600}
      okButtonProps={{
        className: "HJX_Button",
        loading,
        disabled: btnDisabled,
      }}
      onCancel={() => handleClose(true)}
      cancelButtonProps={{
        className: "HJX_Button",
        loading,
        disabled: btnDisabled,
        style: { backgroundColor: "white", color: "black" },
      }}
    >
      <div className={styles.EditPasswordModal}>
        <Spin spinning={loading}>
          <Form form={form} layout="horizontal" colon={false} labelAlign="right" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              name="oldPassword"
              label={t("EditPassordPage_oldPassword_text")}
              getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, "")}
              validateFirst
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("EditPassordPage_oldPassword_error_empty"),
                },
                {
                  required: true,
                  message: t("EditPassordPage_oldPassword_error_emptyString"),
                },
              ]}
            >
              <Input.Password
                className="form__input"
                type="text"
                maxLength={PASSWORD_MAXLEN}
                placeholder={t("EditPassordPage_oldPassword_placeholder")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("EditPassordPage_password_text")}
              getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, "")}
              validateFirst
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("EditPassordPage_password_error_empty"),
                },
                {
                  type: "string",
                  min: PASSWORD_MINLEN,
                  message: t("EditPassordPage_password_error_length"),
                },
                { validator: checkPassword },
                { validator: checkPasswordStrength },
                {
                  validator: checkPasswordSameStaffCD,
                },
              ]}
              dependencies={["oldPassword", "confirmPassword"]}
            >
              <Input.Password
                className="form__input"
                type="text"
                maxLength={PASSWORD_MAXLEN}
                placeholder={t("EditPassordPage_password_placeholder")}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label={t("EditPassordPage_confirmPassword_text")}
              getValueFromEvent={(e) => e.target.value.replace(/(^\s*)|(\s*$)/g, "")}
              validateFirst
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("EditPassordPage_confirmPassword_error_empty"),
                },
                {
                  required: true,
                  message: t("EditPassordPage_confirmPassword_error_emptyString"),
                },
                { validator: checkConfirmPassword },
              ]}
              dependencies={["password"]}
            >
              <Input.Password
                className="form__input"
                type="text"
                maxLength={PASSWORD_MAXLEN}
                placeholder={t("EditPassordPage_confirmPassword_placeholder")}
              />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};
export default EditPasswordModal;
